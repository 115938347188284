body {
  /* background-color: unset; */
  background: #FAFBFC;
}
h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  color: #1D1D1D;
}
table thead tr {
  background: #F6F6F6;
}

.btn {
  padding: 0.525rem 1rem;
}

.mac-scrollbar::-webkit-scrollbar {
  background-color: white;
  width: 16px;
}

/* background of the scrollbar except button or resizer */
.mac-scrollbar::-webkit-scrollbar-track {
  background-color: white;
}

/* scrollbar itself */
.mac-scrollbar::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 16px;
  border: 4px solid white;
}

/* set button(top and bottom of the scrollbar) */
.mac-scrollbar::-webkit-scrollbar-button {
  display:none;
}

.mac-scrollbar-white::-webkit-scrollbar {
  background-color: #fff;
  width: 16px;
}

/* background of the scrollbar except button or resizer */
.mac-scrollbar-white::-webkit-scrollbar-track {
  background-color: #fff;
}

/* scrollbar itself */
.mac-scrollbar-white::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 16px;
  border: 4px solid #fff;
}

/* set button(top and bottom of the scrollbar) */
.mac-scrollbar-white::-webkit-scrollbar-button {
  display:none;
}

@media (min-width: 768px){
  .product-modal {
    max-width: 90%;
  }
}

@media (min-width: 768px){
  .status-modal {
    max-width: 650px;
  }
}

@media (min-width: 992px){
  .status-modal {
    max-width: 800px;
  }
}

/* @media (min-width: 992px){
  .product-modal {
    max-width: 800px;
  }
} */

.cursor-pointer {
  cursor: pointer;
}

.navbar-vertical .navbar-nav .nav-link {
  color: #757575;
}
.navbar-light .navbar-nav .nav-link:hover, 
.navbar-light .navbar-nav .nav-link:focus,
.navbar-vertical .navbar-nav .nav-link.active {
  border-radius: 8px;
  background: #DDECF9;
  color: #166BAE;
}
.navbar-vertical .navbar-nav .nav-link.active:before {
  display: none;
}

.btn-primary {
  background-color: #166BAE;
  border-color: #166BAE;
}

.btn-secondary {
  background-color: #F6F6F6;
  border-color: #F6F6F6;
}

.btn-default {
  background-color: white;
  border-color: white;
  color: #272D37;
}
.btn-default:hover, .btn-default:focus, .btn-default:active {
  background-color: transparent !important;
  color: #166BAE !important;
}

/* .bg-primary, .fill-default {
  fill: #F6F6F6 !important;
  background: #F6F6F6 !important;
  color: black !important;
} */
.bg-product {
  background-color: #F2F8FD;
}
.bg-product-highlight {
  background-color: #FDFBE9;
}

.bg-secondary {
  fill: #F6F6F6 !important;
  background: #F6F6F6 !important;
  /* color: black !important; */
}
.fill-default {
  fill: #F6F6F6 !important;
}

.page-link {
  border: none !important;
  background-color: transparent !important;
  box-shadow: none !important;
}
.page-item.active .page-link {
  /* background: #F6F6F6 !important; */
  color: black !important;
}
a.bg-primary:hover, a.bg-primary:focus, button.bg-primary:hover, button.bg-primary:focus {
  background-color: #F6F6F6 !important;
  font-weight: 600 !important;
}
.bg-custom-yellow {
  background: #FDF5DD !important;
  color: black !important;
}

table tbody tr th, table tbody tr td, table tbody tr td span {
  color: #272D37;
}
input {
  height: 40px !important;
}
.height-30-important {
  height: 30px !important;
}
.product-max-width {
  max-width: 800px;
  margin: auto;
}

.table thead th {
  font-size: 13px !important;
}
table tbody td {
  font-size: 12px !important;
}
table tbody td .dropdown a {
  padding: 0;
}

.nav-item {
  cursor: pointer;
}

.bg-color {
  background-color: #f0f0f1 !important;
}